/**********
 * Theme file: all-variables.scss
 *
 * Erzbistum Bamberg - Common CSS classes.
 */

@import 'all-commons.scss';
@import 'demo-commons.scss';

.subfoot .subfoot-links {
    text-transform: uppercase;
}

// schmuckband does not look good with bamberg styles
.ap-header {
    .bis-schmuckband {
        display: none;
    }
}