/**********
 * Theme file: sytle-bamberg-v1.scss
 *
 * Erzbistum Bamberg CSS - Variation 1.
 */

// Main theme colors
$main-theme:                        #99333e; // erzbistum bamberg red
$main-theme-hover:                  #ac3946; // alternative red
$main-theme-complementary:          #bf404e;

// import common variables for Bamberg
@import 'style-bamberg-variables.scss';


$nav-bg-hover:                      #bf404e;
$nav-text-hover:                    #fff;
$nav-header-bg:                     $main-theme;
$nav-header-menu-bg:                $main-theme;
$nav-header-menu-bg-hover:          #bf404e;
$nav-header-text:                   #fff;
$nav-header-text-active:            #fff;
$nav-menu-border-size:              0;
$nav-menu-border:                   none;
$nav-header-show-arrows:            false;
$nav-header-border-size:            0;
$nav-breadcrump-text:               $text-dark;
$nav-breadcrump-text-hover:         $main-theme-hover;

// map colors (for marker groups)
$map-colors: ($main-theme, #3C1742, #F18F01, #2D728F, #C2F970) !default;


// Import the Apollo default files
@import '../../../apollo-template/apollo-src/scss-themes/imports';

// Import the Bistuemer specific extensions
@import '../scss/imports-all';

// Import Bamberg common styles
@import 'style-bamberg-commons.scss';

// special adjustments
.ap-header {
    .head-navbar {
        background-color: $main-theme;

        @media (min-width: $screen-desktop-min) {
            > .collapse > .container {
                display: flex;
                justify-content: center;
            }
        }
    }
}